import React from 'react';
import styled from 'styled-components';
import dynamic from 'next/dynamic';

const AdsSlot = dynamic(() => import('./AdsSlot'), {
    ssr: false
});

interface Props {
    id: string;
    margin?: string;
}

interface ContainerProps {
    $margin?: string;
}

const Container = styled.div<ContainerProps>`
    @media (min-width: 1266px) {
        display: none;
    }
    width: 300px;
    height: 100px;
    background: rgb(247, 247, 247);
    ${({ $margin }: ContainerProps) => `margin: ${$margin || 'auto'}`}
`;

const SkyscraperMobile = ({ id, margin = 'auto' }: Props): JSX.Element => {
    const DESKTOP_MIN_WIDTH = 1266;
    if (!id) return null;
    return (
        <Container $margin={margin} id={`container-div-sky-desktop-${id}`} key={id}>
            <AdsSlot slotId={id} desktopMinWidth={DESKTOP_MIN_WIDTH} />
        </Container>
    );
};

export default SkyscraperMobile;
